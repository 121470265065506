import React, { ReactElement, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import { Controller, FieldError, UnpackNestedValue, ValidateResult } from 'react-hook-form';
import {
  Alert,
  Select,
  Field,
  TextAreaField,
  DatePickerField,
  RadioGroup,
  CheckList,
  Toggle,
  OptionRenderPropEnhancedArgs,
  CheckListOptionRenderPropEnhancedArgs,
  useDidUpdateEffect,
} from '@odin-labs/components';
import { Dropzone } from 'components/dropzone';
import { Player } from 'components/player';
import { HtmlRenderer } from 'components/htmlRenderer';
import { JobsiteWorkerDocumentUpload } from 'components/jobsiteWorkerDocument';
import { PlacesAutocomplete, Place } from 'components/placesAutocomplete';
import { Section } from 'components/section';
import { Panel } from 'components/panel';
import { Weather } from 'components/weather';
import { camelToKebabCase, objectEntries, objectGet } from 'utils';
import { FormSubmissionDocuments } from 'containers/jobsiteFormSubmission/components';

import type {
  CheckListFormOption,
  FormData,
  FormInput,
  FormElementName,
  RadioGroupFormOption,
  UseFormBuilderArgs,
  UseFormBuilderResult,
} from './types';
import { FormInputTypes, TypedFormInputs, UseInputs } from './types';
import { getEnhancedInputs, resolveInputs, useResolvedInputs } from './utils';

export function useFormBuilder<TFields extends FormData>(
  args: UseFormBuilderArgs<TFields>,
): UseFormBuilderResult<TFields> {
  const {
    methods,
    defaultValues,
    changedValues,
    onSubmit,
    className,
    inputsContainerClassName,
    onIsDirtyChange,
    validationTriggers = [],
    autoFocus,
    fieldsConfig,
    localization,
  } = args;

  const getInputs = (
    inputsArg: FormInput<TFields>[] | TypedFormInputs<TFields> | UseInputs<TFields>,
    fieldPrefix?: string,
  ): FormInput<TFields>[] => {
    const resolvedInputs = resolveInputs(inputsArg, methods, fieldPrefix);
    return getEnhancedInputs({ inputs: resolvedInputs, config: fieldsConfig, localization, autoFocus });
  };

  const resolvedInputs = useResolvedInputs(args.inputs, methods);
  const inputs = React.useMemo(() => {
    return getEnhancedInputs({ inputs: resolvedInputs, config: fieldsConfig, localization, autoFocus });
  }, [resolvedInputs, fieldsConfig, localization, autoFocus]);

  const previousFormValues = React.useRef(defaultValues);

  useEffect((): void => {
    if (JSON.stringify(previousFormValues.current) !== JSON.stringify(defaultValues)) {
      previousFormValues.current = defaultValues;
      methods.reset(defaultValues);
    }
    if (changedValues) {
      objectEntries(changedValues as TFields).forEach(([fieldName, value]) => {
        methods.setValue(fieldName, value, { shouldDirty: true });
      });
    }
  }, [defaultValues]);

  const { isDirty, isSubmitted } = methods?.formState ?? {};

  useDidUpdateEffect(() => {
    onIsDirtyChange?.(isDirty);
  }, [isDirty]);

  // trigger form validation when any of the validationTriggers changes, if the form has been previously submitted
  useEffect(() => {
    if (isSubmitted) {
      methods.trigger();
    }
  }, [...validationTriggers]);

  const methodsRef = React.useRef(methods);
  methodsRef.current = methods;

  const formOnSubmit = (data: UnpackNestedValue<TFields>, event: React.BaseSyntheticEvent<object, any, any>): void => {
    onSubmit(data, event, methodsRef.current.formState.dirtyFields, methodsRef.current);
  };

  const getValidation = (validation: FormInput<TFields>['validation']): FormInput<TFields>['validation'] => {
    const { validate } = validation;

    if (typeof validate === 'function') {
      return {
        ...validation,
        validate: (data): ValidateResult | Promise<ValidateResult> => {
          return validate(data, methods);
        },
      };
    }

    return validation;
  };

  const getInputError = (inputName: FormElementName<TFields>): string => {
    const error = objectGet<FieldError>(inputName.split('.'), methods.errors);
    return error?.message;
  };

  /**
   * @param input Gets current element and renders based off the FormInput type.
   */
  const getCurrentInput = (input: FormInput<TFields>): ReactNode => {
    const validation = input.validation && getValidation(input.validation);
    const label =
      input.element !== FormInputTypes.Section && input.element !== FormInputTypes.Panel
        ? input.elementProps?.label ?? input.label
        : undefined;

    switch (input.element) {
      case FormInputTypes.Toggle: {
        return (
          <Controller
            name={input.name}
            control={methods.control}
            as={Toggle}
            label={label}
            // error={getInputError(input.name)}
            disabled={input.elementProps?.disabled}
            description={input.elementProps?.toggleDescription}
            toggleAlignment={input.elementProps?.toggleAlignment}
            autoFocus={input.elementProps?.autoFocus}
          />
        );
      }
      case FormInputTypes.Field: {
        const { fieldType, cleaveOptions, inputMode, preventLeadingZeros } = input.elementProps ?? {};
        return (
          <Controller
            control={methods.control}
            as={Field}
            name={input.name}
            label={label}
            placeholder={input.elementProps?.placeholder}
            error={getInputError(input.name)}
            loading={input.loading}
            disabled={input.elementProps?.disabled}
            rules={validation}
            type={fieldType}
            icon={input.elementProps?.icon}
            showDefaultIcon={input.elementProps?.showDefaultIcon}
            autoFocus={input.elementProps?.autoFocus}
            maxLength={input.elementProps?.maxLength}
            showLengthCounterNote={input.elementProps?.showLengthCounterNote}
            note={input.elementProps?.note}
            innerRightLabel={input.elementProps?.innerRightLabel}
            {...(fieldType === 'custom' ? { cleaveOptions } : undefined)}
            {...(fieldType === 'number' ? { preventLeadingZeros } : undefined)}
            {...(inputMode ? { inputMode } : undefined)}
          />
        );
      }
      case FormInputTypes.TextAreaField:
        return (
          <Controller
            control={methods.control}
            as={TextAreaField}
            name={input.name}
            label={label}
            placeholder={input.elementProps?.placeholder}
            error={getInputError(input.name)}
            loading={input.loading}
            disabled={input.elementProps?.disabled}
            rules={validation}
            autoFocus={input.elementProps?.autoFocus}
            maxLength={input.elementProps?.maxLength}
            showLengthCounterNote={input.elementProps?.showLengthCounterNote}
            note={input.elementProps?.note}
            size={input.elementProps?.size}
            maxRows={input.elementProps?.maxRows}
            autosize={input.elementProps?.autosize}
          />
        );
      case FormInputTypes.Select:
        return (
          <Controller
            control={methods.control}
            as={Select}
            name={input.name}
            label={label}
            placeholder={input.elementProps?.placeholder}
            error={getInputError(input.name)}
            loading={input.loading}
            options={input.elementProps?.options}
            rules={validation}
            isDisabled={input.elementProps?.disabled}
            icon={input.elementProps?.icon}
            isMulti={input.elementProps?.multiple}
            clearToNull={input.elementProps?.clearToNull}
            isClearable={input.elementProps?.isClearable}
            autoFocus={input.elementProps?.autoFocus}
            note={input.elementProps?.note}
            closeMenuOnSelect={!input.elementProps?.multiple}
          />
        );
      case FormInputTypes.DatePicker: {
        const elementProps = input.elementProps as Omit<FormInput<TFields>['elementProps'], 'onChange'>;
        return (
          <Controller
            control={methods.control}
            as={DatePickerField}
            name={input.name}
            label={label}
            error={getInputError(input.name)}
            loading={input.loading}
            rules={validation}
            {...elementProps}
          />
        );
      }
      case FormInputTypes.Dropzone: {
        const elementProps = input.elementProps as Omit<typeof input.elementProps, 'onChange' | 'size'>;
        return (
          <Controller
            control={methods.control}
            as={Dropzone}
            name={input.name}
            label={label}
            error={getInputError(input.name)}
            loading={input.loading}
            rules={validation}
            {...elementProps}
          />
        );
      }
      case FormInputTypes.Player: {
        const elementProps = input.elementProps as React.ComponentProps<typeof Player>;
        return (
          <Controller
            control={methods.control}
            as={Player}
            name={input.name}
            label={label}
            error={getInputError(input.name)}
            rules={validation}
            {...elementProps}
          />
        );
      }
      case FormInputTypes.HtmlRenderer: {
        const elementProps = input.elementProps as React.ComponentProps<typeof HtmlRenderer>;
        return (
          <Controller
            control={methods.control}
            as={HtmlRenderer}
            name={input.name}
            label={label}
            error={getInputError(input.name)}
            rules={validation}
            {...elementProps}
          />
        );
      }
      case FormInputTypes.Alert:
        return <Alert {...(input.elementProps as unknown as React.ComponentProps<typeof Alert>)} />;
      case FormInputTypes.Weather:
        return <Weather {...(input.elementProps as unknown as React.ComponentProps<typeof Weather>)} />;
      case FormInputTypes.FormSubmissionDocuments:
        return (
          <Controller
            control={methods.control}
            name={input.name}
            as={FormSubmissionDocuments}
            label={label}
            rules={validation}
            {...input.elementProps}
          />
        );
      case FormInputTypes.CustomInput: {
        const { customInput: CustomInput, ...restInputProps } = input.elementProps;
        return (
          <Controller
            control={methods.control}
            name={input.name}
            as={CustomInput}
            label={label}
            error={getInputError(input.name)}
            rules={validation}
            {...restInputProps}
          />
        );
      }
      case FormInputTypes.CustomContent: {
        const { content, ...restElementProps } = input.elementProps ?? {};
        if (typeof content === 'function') {
          return content(restElementProps);
        }
        return content;
      }
      case FormInputTypes.JobsiteWorkerDocument: {
        return (
          <JobsiteWorkerDocumentUpload
            label={label}
            {...(input.elementProps as React.ComponentProps<typeof JobsiteWorkerDocumentUpload>)}
            files={
              input.files &&
              getInputs(input.files).map((fileChild) => {
                return (
                  <div key={fileChild.name} className={cn(fileChild.layout)}>
                    {getCurrentInput(fileChild)}
                  </div>
                );
              })
            }
          >
            {getInputs(input.children).map((child) => {
              return (
                <div key={child.name} className={cn(child.layout)}>
                  {getCurrentInput(child)}
                </div>
              );
            })}
          </JobsiteWorkerDocumentUpload>
        );
      }
      case FormInputTypes.PlacesAutocomplete: {
        return (
          <Controller
            control={methods.control}
            as={PlacesAutocomplete}
            name={input.name}
            label={label}
            error={getInputError(input.name)}
            loading={input.loading}
            rules={validation}
            // add `elementProps` before onCommit, so that elementProps.onCommit doesn't override below onCommit
            {...(input.elementProps as Omit<FormInput<TFields>['elementProps'], 'onChange'>)}
            onCommit={(value: Place): void => {
              input.elementProps?.onCommit?.(value, methods);
            }}
          />
        );
      }
      case FormInputTypes.RadioGroup: {
        const radioGroupOptions = (input.elementProps?.options as RadioGroupFormOption<TFields>[]).map(
          ({ inputs: optionInputs, inputsVisibility, inputsContainerLayout, ...option }) => {
            if (optionInputs) {
              const formElements = optionInputs?.map((optionInput: FormInput<TFields>) => {
                return (
                  <div key={optionInput.name} className={cn(optionInput.layout)}>
                    {getCurrentInput(optionInput)}
                  </div>
                );
              });

              return {
                ...option,
                children: React.useCallback(
                  ({ checked }: OptionRenderPropEnhancedArgs): React.ReactNode => {
                    if (inputsVisibility === 'always' || checked) {
                      return inputsContainerLayout ? (
                        <div className={inputsContainerLayout}>{formElements}</div>
                      ) : (
                        formElements
                      );
                    }
                    return null;
                  },
                  [inputsVisibility, inputsContainerLayout, formElements],
                ),
              };
            }
            return option;
          },
        );
        return (
          <Controller
            control={methods.control}
            as={RadioGroup}
            layout={input.elementProps.layout}
            name={input.name}
            label={label}
            options={radioGroupOptions}
            rules={validation}
            preventSubmitOnEnter={input.elementProps?.preventSubmitOnEnter}
          />
        );
      }
      case FormInputTypes.CheckList: {
        const checkListOptions = (input.elementProps?.options as CheckListFormOption<TFields>[]).map(
          ({ inputs: optionInputs, inputsVisibility, inputsContainerLayout, ...option }) => {
            if (optionInputs) {
              const formElements = optionInputs?.map((optionInput: FormInput<TFields>) => {
                return (
                  <div key={optionInput.name} className={cn(optionInput.layout)}>
                    {getCurrentInput(optionInput)}
                  </div>
                );
              });

              return {
                ...option,
                children: React.useCallback(
                  ({ selected }: CheckListOptionRenderPropEnhancedArgs): React.ReactNode => {
                    if (inputsVisibility === 'always' || selected) {
                      return inputsContainerLayout ? (
                        <div className={inputsContainerLayout}>{formElements}</div>
                      ) : (
                        formElements
                      );
                    }
                    return null;
                  },
                  [inputsVisibility, inputsContainerLayout, formElements],
                ),
              };
            }
            return option;
          },
        );
        return (
          <Controller
            control={methods.control}
            as={CheckList}
            layout={input.elementProps.layout}
            name={input.name}
            label={label}
            options={checkListOptions}
            rules={validation}
            preventSubmitOnEnter={input.elementProps?.preventSubmitOnEnter}
          />
        );
      }
      default:
        return null;
    }
  };

  const resolveElement = (input: FormInput<TFields>): ReactElement => {
    switch (input.element) {
      case FormInputTypes.Layout:
        return (
          <div key={input.name} className={cn(input.layout)}>
            {getInputs(input.children).map((child) => {
              return resolveElement(child);
            })}
          </div>
        );

      case FormInputTypes.Section: {
        const sectionChildren = getInputs(input.children, input.name);
        return (
          <Section
            key={input.name}
            id={camelToKebabCase(input.name)}
            {...input.elementProps}
            className={cn(input.layout)}
          >
            {sectionChildren.map((child) => {
              return resolveElement(child);
            })}
          </Section>
        );
      }

      case FormInputTypes.Panel: {
        const panelChildren = getInputs(input.children, input.name);
        return (
          <Panel key={input.name} {...input.elementProps} className={cn(input.layout)}>
            {panelChildren.map((child) => {
              return resolveElement(child);
            })}
          </Panel>
        );
      }

      default:
        return (
          <div key={input.name} className={cn(input.layout)}>
            {getCurrentInput(input)}
          </div>
        );
    }
  };

  const formElements = inputs?.map((input: FormInput<TFields>) => {
    return resolveElement(input);
  });

  // leave `row` for backwards-compatibility, only when no `className` prop is specified
  const formClassName = className ?? (inputsContainerClassName ? undefined : 'row');

  return {
    formOnSubmit,
    formElements,
    formClassName,
  };
}

import queryString from 'query-string';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';

import { usePageQueryParams } from 'utils/usePageQueryParams';
import { DashboardTabProps } from 'containers/dashboard/tabs/types';
import { Col } from 'components/container';
import {
  ArcElement,
  Chart as ChartJS,
  LineElement,
  LineController,
  BarElement,
  Colors,
  BubbleController,
  CategoryScale,
  LinearScale,
  Legend,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
  ChartEvent,
  ActiveElement,
  ChartTypeRegistry,
} from 'chart.js';
import autocolors from 'chartjs-plugin-autocolors';
import { ElasticFacetEntry } from 'apollo/generated/client-operations';
import { DashboardStatsOverview } from 'containers/dashboard/tabs/stats/DashboardStatsOverview';

export function DashboardGraphsTab(props: DashboardTabProps): React.ReactElement {
  const { data, jobsites, isCurrentlyOnsiteVisible, loading } = props;

  const location = useLocation();
  let { pie: pieChartType = 'pie' } = (queryString.parse(location.search) as { pie: keyof ChartTypeRegistry }) || {};
  if (!(pieChartType === 'pie' || pieChartType === 'doughnut')) {
    pieChartType = 'pie';
  }

  const { updateUrl } = usePageQueryParams();
  const {
    jobsiteBreakdowns,
    dailyBreakdowns,
    languageBreakdowns,
    raceBreakdowns,
    genderBreakdowns,
    onboardedBreakdowns,
    bannedBreakdowns,
    ageBreakdowns,
  } = data || {};

  ChartJS.register([
    ArcElement,
    BarElement,
    BubbleController,
    CategoryScale,
    Colors,
    LinearScale,
    LineController,
    LineElement,
    Legend,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Tooltip,
    Title,
    autocolors,
  ]);
  // ChartJS.register(zoomPlugin);
  // useEffect(() => {}, []);

  return (
    <div className="odin-flex odin-flex-col odin-gap-y-9">
      <div className="odin-flex odin-flex-col odin-gap-y-9">
        <DashboardStatsOverview
          workerReportsData={data}
          isCurrentlyOnsiteVisible={isCurrentlyOnsiteVisible}
          loading={loading}
        />
      </div>
      <Chart
        type="bar"
        options={{
          responsive: true,
          aspectRatio: 6,
          parsing: { xAxisKey: 'name', yAxisKey: 'count' },
          plugins: {
            colors: { enabled: true, forceOverride: true },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx: TooltipItem<'bar'> & { raw: ElasticFacetEntry }): string => `Manpower: ${ctx.raw.count}`,
              },
            },
          },
          scales: {
            x: {
              grid: { display: false },
              // type: 'timeseries',
              // time: {
              //   minUnit: 'day',
              //   tooltipFormat: 'MMM dd, yyyy',
              //   unit: 'day',
              // },
            },
          },
        }}
        data={{
          labels: dailyBreakdowns?.map((j) => j.name) || [],
          datasets: [
            {
              label: 'Total manpower, per day',
              data: dailyBreakdowns || [],
            },
          ],
        }}
      />
      <Chart
        type="bar"
        options={{
          responsive: true,
          aspectRatio: 5,
          plugins: {
            autocolors: { mode: 'data' },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx: TooltipItem<'bar'> & { raw: ElasticFacetEntry }): string => `Manpower: ${ctx.raw.count}`,
              },
            },
          },
          parsing: { xAxisKey: 'name', yAxisKey: 'count' },
          events: ['click', 'mousemove'],
          onHover: (event: ChartEvent, elements: ActiveElement[], chart: any): void => {
            if (elements.length) {
              // eslint-disable-next-line no-param-reassign
              chart.canvas.style.cursor = 'pointer';
            } else {
              // eslint-disable-next-line no-param-reassign
              chart.canvas.style.cursor = 'default';
            }
          },
          onClick: (e, item): void => {
            if (!item || !item.length) {
              return;
            }
            const { index } = item[0] || {};
            if (index > jobsiteBreakdowns.length) {
              return;
            }
            const current = jobsiteBreakdowns[index];
            const { name: jobsiteName } = current || {};
            const jobsite = jobsites.find((j) => j.name === jobsiteName);
            if (jobsite) {
              updateUrl({ jobsiteIds: [jobsite.jobsiteId] });
            }
          },
        }}
        data={{
          labels: jobsiteBreakdowns?.map((j) => j.name) || [],
          datasets: [
            {
              label: 'Total manpower, per jobsite',
              data: jobsiteBreakdowns || [],
            },
          ],
        }}
      />

      <Chart
        type="bar"
        options={{
          responsive: true,
          aspectRatio: 5,
          scales: { y: { beginAtZero: true } },
          plugins: {
            autocolors: { mode: 'dataset' },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx: TooltipItem<'bar'> & { raw: ElasticFacetEntry }): string =>
                  `${ctx.dataset.label} Workers: ${ctx.raw.count}`,
              },
            },
          },
          parsing: { xAxisKey: 'name', yAxisKey: 'count' },
        }}
        data={{
          labels: onboardedBreakdowns?.map((j) => j.name) || [],
          datasets: [
            {
              label: 'Onboarded',
              data: onboardedBreakdowns || [],
            },
            {
              label: 'Banned',
              data: bannedBreakdowns || [],
            },
          ],
        }}
      />
      <div className="lg:odin-grid lg:odin-grid-cols-2 xl:odin-grid-cols-3 lg:odin-gap-9">
        <Col>
          <Chart
            type="pie"
            options={{
              responsive: true,
              aspectRatio: 2,
              // parsing: { key: 'count' },
              plugins: {
                title: { display: true, text: 'Language breakdown', position: 'top', fullSize: true, align: 'start' },
                autocolors: { mode: 'data' },
                legend: { display: true, position: 'right' },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (ctx: TooltipItem<'pie'> & { raw: ElasticFacetEntry }): string =>
                      `${ctx.raw.count} - ${ctx.raw.percentage}%`,
                  },
                },
              },
            }}
            data={{
              labels: languageBreakdowns?.map((l) => l.name) || [],
              datasets: [
                {
                  // data: languageBreakdowns?.map((l) => l.count) || [],
                  data: languageBreakdowns || [],
                  parsing: { key: 'count' },
                },
              ],
            }}
          />
        </Col>
        <Col>
          <Chart
            type={pieChartType}
            options={{
              responsive: true,
              aspectRatio: 2,
              plugins: {
                title: { display: true, text: 'Race breakdown', position: 'top', fullSize: true, align: 'start' },
                autocolors: { mode: 'data' },
                legend: { display: true, position: 'right' },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (ctx: TooltipItem<'pie' | 'doughnut'> & { raw: ElasticFacetEntry }): string =>
                      `${ctx.raw.count} - ${ctx.raw.percentage}%`,
                  },
                },
              },
            }}
            data={{
              labels: raceBreakdowns?.map((l) => l.name) || [],
              datasets: [
                {
                  data: raceBreakdowns || [],
                  parsing: { key: 'count' },
                },
              ],
            }}
          />
        </Col>
        <Col>
          <Chart
            type={pieChartType}
            options={{
              responsive: true,
              aspectRatio: 2,
              plugins: {
                title: { display: true, text: 'Gender breakdown', position: 'top', fullSize: true, align: 'start' },
                autocolors: { mode: 'data' },
                legend: { display: true, position: 'right' },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (ctx: TooltipItem<'pie' | 'doughnut'> & { raw: ElasticFacetEntry }): string =>
                      `${ctx.raw.count} - ${ctx.raw.percentage}%`,
                  },
                },
              },
            }}
            data={{
              labels: genderBreakdowns?.map((l) => l.name) || [],
              datasets: [
                {
                  data: genderBreakdowns || [],
                  parsing: { key: 'count' },
                },
                // {
                //   data: raceBreakdowns || [],
                //   parsing: { key: 'count' },
                // },
              ],
            }}
          />
        </Col>
        <Chart
          type="line"
          options={{
            responsive: true,
            aspectRatio: 6,
            parsing: { xAxisKey: 'name', yAxisKey: 'count' },
            plugins: {
              colors: { enabled: true, forceOverride: true },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (ctx: TooltipItem<'line'> & { raw: ElasticFacetEntry }): string => `Workers: ${ctx.raw.count}`,
                },
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                grid: { display: false },
              },
              y: {
                beginAtZero: true,
              },
            },
          }}
          data={{
            labels: ageBreakdowns?.map((j) => j.name) || [],
            datasets: [
              {
                label: 'Age',
                data: ageBreakdowns || [],
                fill: true,
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
